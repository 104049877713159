<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <MemberRequests />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MemberRequests from "./tables/incomingMemberRequests.vue";

export default {
    components: { MemberRequests },
    data: () => ({}),
    computed: {},
    methods: {},
    mounted() {},
};
</script>

<style></style>
