<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import CrmView from "@/components/crm/crmView.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Member Requests",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, CrmView },
    data() {
        return {
            title: "Member Requests",
            items: [
                {
                    text: "Utility",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <CrmView />
    </Layout>
</template>
